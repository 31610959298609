import { faEnvelope, faLocationDot, faPhoneVolume } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Toastify from 'toastify-js'; // Toastify for feedback
import "toastify-js/src/toastify.css"; // Include Toastify CSS

export default function GetInTouch() {
    const [formData, setFormData] = useState({
        fullname: '',
        email: '',
        phonenumber: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://navatrisdev.novatales.com/contactus.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
            const result = await response.json();
            if (result.type === 'success') {
                Toastify({
                    text: result.message,
                    backgroundColor: "green",
                    position: "top-right"
                }).showToast();
                setFormData({ fullname: '', email: '', phonenumber: '', message: '' });
            } else {
                throw new Error(result.message);
            }
        } catch (error) {
            Toastify({
                text: `Error: ${error.message}`,
                backgroundColor: "red",
                position: "top-right"
            }).showToast();
        }
    };

    return (
        <div className="contact">
            <Container fluid>
                <Row > 
                    <Col xxl={4} lg={12}>
                        <Row >
                            <div className="contact-info ">
                                <h6>Contact us</h6>
                                <h2>Get in touch</h2>
                            </div>
                        </Row>
                    </Col>
                    <Col xxl={8} lg={12}  className='lg:ml-48 ml-0 xl:ml-64' >
                        {/* <Row>
                            <Col lg={3} className="location mr-0 sm:ml-20 sm:mr-28">
                                <div>
                                    <FontAwesomeIcon icon={faLocationDot} />
                                </div>
                                <span>Endula Empresa,<br /> 4th Floor, Kondapur, Hyderabad,<br />Telangana-500084</span>
                            </Col>
                           
                            <Col lg={3} className="actions-item d-flex justify-content-center align-items-center">
                                <div>
                                    <FontAwesomeIcon icon={faEnvelope} />
                                </div>
                                <span>info@dsroffice.com</span>
                            </Col>
                        </Row> */}
                    </Col>
                </Row>
                <Row className="d-flex justify-content-between align-items-center">
                    <form className="contact-right__form" onSubmit={handleSubmit}>
                        <h3>Contact form</h3>
                        <Row className="contact-form d-flex justify-content-between align-items-center">
                            <Col lg={6}>
                                <div className="custom-input">
                                    <input name="fullname" type="text" value={formData.fullname} onChange={handleChange} required />
                                    <span>Name</span>
                                </div>
                                <div className="custom-input">
                                    <input name="phonenumber" type="text" value={formData.phonenumber} onChange={handleChange} required />
                                    <span>Phone</span>
                                </div>
                                <div className="custom-input">
                                    <input name="email" type="email" value={formData.email} onChange={handleChange} required />
                                    <span>Email</span>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="custom-input">
                                    <textarea name="message" value={formData.message} onChange={handleChange} required></textarea>
                                    <span>Message</span>
                                </div>
                            </Col>
                        </Row>
                        <Row className="contact-form__submit">
                            <Col lg={3}></Col>
                            <Col lg={3}>
                                <button type="submit" className="custom-btn-secondary">Submit</button>
                            </Col>
                            <Col lg={3}></Col>
                        </Row>
                    </form>
                </Row>
            </Container>
        </div>
    );
}
