import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import "../css/funds.css";
import FundsImage from "../images/fund.png";
export default function Funds() {
  useEffect(() => {
    // Scroll to the top of the window when the component loads
    window.scroll(0, 0);
  }, []); // Empty dependency array ensures this runs only on mount
  return (
    <div>
      <section className="funds">
        <Container fluid>
          <div className="top-links">
            <Link to="/">
              {" "}
              <span className="home-link">Home</span>
            </Link>
            <FontAwesomeIcon icon={faArrowRight} className="ps-3 pe-3" />
            <span className="page-link">Funds</span>
          </div>
          <Row className="Investment-Criteria">
            <Col lg={6}>
              <h6>Funds</h6>
              <h2>Our Investment Criteria</h2>
              <p>
                We focus on balancing the risk and making more profit, we make
                investments with ourplanned strategies which are completely data
                driven and make informed decisions considering from the minds of
                experts. We offer our investments in the following ways:
              </p>
            </Col>
            <Col lg={6}>
              <img src={FundsImage} alt="" />
            </Col>
          </Row>
          <div className="ways">
            <Row>
              <h3>Private Equity</h3>
              <p>
                When it comes to the right opportunities in the consideration of
                identifying and funding firms whose growth strategies are most
                likely to more greatly, we search. The PE model is not just
                injection of capital into these companies and as such we help
                these businesses to succeed.
              </p>
            </Row>
            <Row>
              <h3>Investment Solutions</h3>
              <p>
                Our prime focus is to construct various managed investment
                products that are exclusive from other conventional products in
                the market, intended for successful investment, consistent and
                steady growth and minimized risks respectively based on market
                intelligence. These are listed equities, venture capital
                investments and unlisted securities thus diversifying the
                financial market for our investees to fulfill their dream of
                financial goal.
              </p>
            </Row>
            <Row>
              <h3>Debt Financing</h3>
              <p>
                Our debt financing is ready to fulfill the requirements of any
                company that needs appropriate capital for further development
                or just to strengthen its position. We provide debt strategies
                and structures that assists in the non – imposition or
                minimization in the contact with the financial position of
                investees which in turn assist in also enhancing company’s
                resourceful returns.
              </p>
            </Row>
          </div>
        </Container>
      </section>
    </div>
  );
}
