import { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar'; 
import NavDropdown from 'react-bootstrap/NavDropdown';
import Logo from '../images/Navatris logo-01.png';
import '../css/CustomNavbar.css';
import { Link } from 'react-router-dom';

export default function CustomNavbar() {
    const [isActive, setIsActive] = useState(false);
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {  // Adjust scroll threshold as needed
                setIsActive(true);
            } else {
                setIsActive(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleNavLinkClick = () => {
        setExpanded(false); // Close the navbar toggle
    };

    return (
        <header className={isActive ? 'active' : ''}>
            <Navbar expand="lg" expanded={expanded}>
                <Container fluid>
                    <Navbar.Brand as={Link} to="/">
                        <img src={Logo} alt="NavatrisLogo" />
                    </Navbar.Brand>
                    <Navbar.Toggle 
                        aria-controls="navbarScroll" 
                        onClick={() => setExpanded(expanded ? false : true)} 
                    />
                    <Navbar.Collapse id="navbarScroll">
                        <Nav
                            className="mx-auto my-2 my-lg-0"
                            navbarScroll
                        >        
                            <Nav.Link as={Link} to="/What-we-do" onClick={handleNavLinkClick}>
                                What we do
                            </Nav.Link>
                            <Nav.Link as={Link} to="/Industries" onClick={handleNavLinkClick}>
                                Industries
                            </Nav.Link>
                            <Nav.Link as={Link} to="/Funds" onClick={handleNavLinkClick}>
                                Funds
                            </Nav.Link>
                        </Nav>
                        <Link 
                            to="/Aboutus" 
                            className="custom-btn-primary justify-content-center d-flex" 
                            onClick={handleNavLinkClick}
                        >
                            About us
                        </Link>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    );
}
