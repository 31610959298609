import React, { useEffect } from "react";
import "../css/whatwedo.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Col, Container, Row } from "react-bootstrap";
import achivementImage from "../images/whatwedo.png";
import Strategic from "../images/strategic risk management.png";
import perspective from "../images/Long term perspective Icon.png";
import Portfolio from "../images/active porfolio management.png";
import Collaborative from "../images/Collaborative Relationships.png";
export default function WhatWeDo() {
  useEffect(() => {
    // Scroll to the top of the window when the component loads
    window.scroll(0, 0);
  }, []); // Empty dependency array ensures this runs only on mount
  return (
    <div>
      <div className="What-we-do-page">
        <Container fluid>
          <div className="top-links">
            <Link to="/">
              {" "}
              <span className="home-link">Home</span>
            </Link>
            <FontAwesomeIcon icon={faArrowRight} className="ps-3 pe-3" />
            <span className="page-link">what we do</span>
          </div>
          <div className="whatwedo-page-content ">
            <Row
              className="achievements-row
                        "
            >
              <Col lg={6} className="p-4 ">
                <div className="sm:m-10">
                  <img src={achivementImage} />
                </div>
              </Col>
              <Col lg={6} className="ps-lg-5 flex justify-center items-center">
                <div>
                  <h2>What do we Do</h2>
                  <p>
                    We focus on balancing the risk and making more profit, we
                    make investments with ourplanned strategies which are
                    completely data driven and make informed decisions
                    considering from the minds of experts.{" "}
                  </p>
                </div>
              </Col>
            </Row>
          </div>
          <section className="Investment-framework">
            <Row className="d-flex justify-content-center align-items-center">
              <Col lg={4}>
                <h2>Our Investment Framework</h2>
                <p>
                  At Navatris Investments, we strictly follow the philosophy of
                  Collaborative Growth. We believe in working as a team and with
                  like-minded people to achieve mutual growth and development.{" "}
                </p>
                <p>
                  We execute and drive our unique strategies from our
                  Foundational Fundamental pillars:
                </p>
              </Col>
              <Col lg={1}></Col>
              <Col lg={7} className="ps-lg-5">
                <Row className="mb-5">
                  <Col lg={6}>
                    <div>
                      <Row>
                        <Col
                          lg={2}
                          md={4}
                          className="text-center stratagyIcons"
                        >
                          <img src={Strategic} alt="" />
                        </Col>
                        <Col lg={10} md={8} className="ps-lg-5">
                          <h4>Strategic Risk Management</h4>
                          <p>
                            We proactively identify and address the underlying
                            risks to secure the performance of our investees.
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div>
                      <Row>
                        <Col
                          lg={2}
                          md={4}
                          className="text-center stratagyIcons"
                        >
                          <img src={perspective} alt="" />
                        </Col>
                        <Col lg={10} md={8} className="ps-lg-5">
                          <h4>Long-term perspective</h4>
                          <p>
                            We focus on sustainable growth and make sure that
                            the wealth grows steadily with time generating a
                            long-term fund flow.
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-5">
                  <Col lg={6}>
                    <div>
                      <Row>
                        <Col
                          lg={2}
                          md={4}
                          className="text-center stratagyIcons"
                        >
                          <img src={Portfolio} alt="" />
                        </Col>
                        <Col lg={10} md={8} className="ps-lg-5">
                          <h4>Active Portfolio Management</h4>
                          <p>
                            We continuously monitor your portfolio and make
                            suitable changes according to the market needs.
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div>
                      <Row>
                        <Col
                          lg={2}
                          md={4}
                          className="text-center stratagyIcons"
                        >
                          <img src={Collaborative} alt="" />
                        </Col>
                        <Col lg={10} md={8} className="ps-lg-5">
                          <h4>Collaborative Relationships with Investees</h4>
                          <p>
                            When we invest in our partner company, we closely
                            monitor their performances, provide our expertise
                            and ensure them that they achieve success.
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </section>
        </Container>
      </div>
    </div>
  );
}
