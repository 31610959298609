import React, { useEffect } from "react";
import "../css/Industries.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Container, Row, Col } from "react-bootstrap";
import GetInTouch from "./GetInTouch";
import real_estate from "../images/real_estate.png";
import investment from "../images/Investment Equity.png";
import IndustriesmainImage from "../images/Industries.png";
import debt from "../images/Debt Financing.png";
import venture from "../images/Venture Capitalism.png";
import finance from "../images/Finance.png";
import defence from "../images/defence_360.png";
import lifeScience from "../images/life_science_360.png";
import { useLocation } from "react-router-dom";
export default function Industries() {
  useEffect(() => {
    // Scroll to the top of the window when the component loads
    window.scroll(0, 0);
  }, []); // Empty dependency array ensures this runs only on mount
  const location = useLocation();

  useEffect(() => {
    // Scroll to the element with an offset
    if (location.hash) {
      const element = document.getElementById(location.hash.replace("#", ""));
      if (element) {
        const yOffset = -120; // Adjust this value for your desired margin
        const yPosition =
          element.getBoundingClientRect().top + window.scrollY + yOffset;
        window.scrollTo({ top: yPosition, behavior: "smooth" });
      }
    }
  }, [location]);
  return (
    <div>
      <div className="Industries-page">
        <Container fluid>
          <div className="top-links">
            <Link to="/">
              {" "}
              <span className="home-link">Home</span>
            </Link>
            <FontAwesomeIcon icon={faArrowRight} className="ps-3 pe-3" />
            <span className="page-link">Industries</span>
          </div>
          <div className="industries-page-content">
            <Row className="justify-content-center align-item-center d-flex">
              <Col lg={6}>
                <h6>Diverse Portfolio across High-Impact Industries</h6>
                <h2>Industries</h2>
                <p className="text-xl">
                  We are committed to invest in firms that makes a promising
                  difference in the society with outstanding ideas. We fuel the
                  vision with our capital support to enhance growth. Following
                  are the industries where our investment plays a significant
                  role:
                </p>
              </Col>
              <Col lg={6}>
                <img src={IndustriesmainImage} />
              </Col>
            </Row>
          </div>
          <section className="Realestate-Industries">
            <div id="realEstate">
              <img
                className="w-20 sm:w-36"
                src={real_estate}
                alt="real_estate"
              />
            </div>
            <h2>Real Estate</h2>
            <p>
              At Navatris, we are specialized in recognizing high-quality real
              estate projects particularly housing and office complexes which
              have a future growth capabilities and high chances of
              profitability. We apply our data driven strategies to invest in
              the properties in the emerging market that yields a high-income
              source and is sustainable in nature. We ensure that through our
              efforts, our investees gain significant profits and are exposed to
              high-end opportunities. Our transparent process and proven track
              records ensures the profitable investments in real estates.
            </p>
            <p>
              Our investees obtain an opportunity to get connected with the team
              having deeper understanding of the dynamics in the global market
              of real estate. We invest in those properties that in the
              alignment with the demand of present market and future growth
              prospects.
            </p>
          </section>
          <section>
            <div className="backEnd__arrows">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </section>
          <section className="Realestate-Industries">
            <div id="investMent">
              <img
                className="w-20  sm:w-36"
                src={investment}
                alt="real_estate"
              />
            </div>
            <h2>Investment Equity</h2>
            <p>
              Our investment portfolio covers both listed and unlisted market
              space offering investments in the established companies as well as
              in the private enterprises. All the companies and ventures are
              backed with our innovative ideas that has a high potential to
              grow. Implementing this method allows us to disperse our risk
              factor while we provide capital across various ventures
            </p>
            <p>
              In either of our investment categories, we majorly focus on
              long-term goals that create the high impact by promoting
              sustainable growth. With this our aim remains to amplify profits
              and maintain a portfolio that stands out
            </p>
          </section>
          <section id="debtFinance" className="Realestate-Industries">
            <div>
              <img className="w-20  sm:w-36" src={debt} alt="real_estate" />
            </div>
            <h2>Debt Financing</h2>
            <p>
              We empower businesses and entrepreneurs by offering tailored debt
              financing solutions and help them achieve goals without diluting
              the ownership and equity. We offer funds in competitive terms
              along with personalized repayments to ensure our investees achieve
              their goals. We provide debt financing solutions in various
              industries with our commitment to transparent transaction process,
              trust and commitment to our collaborative agreement.
            </p>
            <p>
              This makes sure that our investees concentrate on scaling their
              business without getting worried about their financial stability.
              Our debt financing solution meet the needs of various industries
              inspire us to be a trusted ally.
            </p>
          </section>
          <section className="Realestate-Industries">
            <div id="venture">
              <img className="w-20  sm:w-36" src={venture} alt="real_estate" />
            </div>
            <h2>Venture Capitalism</h2>
            <p>
              At Navatris, we are fuelled with passion to give a catalyst for
              growth to the start- ups and visionary entrepreneurs through our
              venture capital investments who aim in driving revolutionary
              change. We focus on early-stage ventures having growth potential.
              With our financial support and extensive industrial expertise will
              let your business to reach new heights with the network of
              influential connections
            </p>
            <p>
              We empower our investees by our positive environment to
              revolutionize their innovative concepts into the definitive
              outcomes by providing them tailored financial backup. It ensures
              and helps them to create a lasting impression. We are focused to
              make your business leave a lasting legacy. Our strong foundation
              built on trust with a belief of mutual growth makes us align with
              your vision.
            </p>
            <p>
              Our commitment at Navatris thrives us to foster the environment to
              create a successful story by ensuring the businesses achieve new
              milestones under our supervision that inspires the next generation
              innovative startups
            </p>
          </section>
          <section className="Realestate-Industries">
            <div id="LifeScience">
              <img
                className="w-20  sm:w-36"
                src={lifeScience}
                alt="real_estate"
              />
            </div>
            <h2>Life Sciences</h2>
            <p>
              Leveraging the expertise of our founder in the pharmaceutical
              industry we are committed to promote quality services in the
              domain of life sciences and advance human health through our
              strategic investments.
            </p>
            <p>
              We deal with Pharmaceuticals, Healthcare and Biotechnology where
              we actively get involved with firms involved in production of
              revolutionary drugs, providing quality healthcare, and promoting
              accessibility to healthcare services through their innovative
              ideas.
            </p>
            <p>
              With the combination of our industrial insights and funding
              solutions, we majorly focus on the industries that are keen in
              developing groundbreaking treatments and medical solutions that
              solves the current challenges in the field
            </p>
            <p>
              We aim to create a positive difference through healthcare industry
              by nurturing the future biotechnologies and pharmaceutical firms
              which creates an ecosystem of healthy life.
            </p>
          </section>
          {/* <section className="Realestate-Industries">
            <h6>Industries</h6>
            <h2>Healthcare</h2>
            <p>
              As a sector centered around our mission, investment has now
              concentrated on quality healthcare that is enhanced by new-age
              facilities and service providers. We fund institutions engaged in
              efforts to enhance patients’ status and promote the accessibility
              of healthcare services.
            </p>
          </section>
          <section className="Realestate-Industries">
            <h6>Industries</h6>
            <h2>Biotechnology</h2>
            <p>
              Knowing the impact of biotechnology in healthcare sectors, we get
              involved with firms involved in research and development. We
              provide our investment from starting to the establishedplayers in
              the pharma who seeks for new therapies and advanced
              instrumentation to rethink medication.
            </p>
          </section> */}
          <section className="Realestate-Industries">
            <div id="finance">
              <img className="w-20  sm:w-36" src={finance} alt="real_estate" />
            </div>
            <h2>Finance</h2>
            <p>
              Our investments within finance focus on innovative technologies
              and the firms that transform the financing industry. Using the
              powers of ICTs, we advocate for new solutions for the firms like
              fintech applications, blockchain systems and digital payment
              platforms. These are the platforms which creates the opportunities
              for businesses and individuals to promote their efficiency and
              offer security.
            </p>
            <p>
              We aim for creating a sustainable financial ecosystem that
              empowers the innovation by overcoming challenges in the economy.
            </p>
          </section>
          <section className="Realestate-Industries">
            <div id="defence">
              <img className="w-20  sm:w-36" src={defence} alt="real_estate" />
            </div>
            <h2>Defence industry</h2>
            <p>
              At Navatris, we dedicate our focus to support the innovative
              business ideas that build visionary equipment in the defence
              sector. Our commitment to empowerment extends to the defence
              industry too by providing our financial solutions by investing in
              the future defence technology and contributing to the national
              interest of security and safety.
            </p>
            <p>
              To be in alignment with our primary commitment, we provide our
              funding in the aerospace industry, naval defence, and military
              research & development ensuring to strengthen national security.
              We aim for the cutting-edge solutions and innovations to meet the
              evolving challenges in the modern defence systems.
            </p>
            <p>
              We believe in engineering the future of defence from advanced
              aerospace technologies to the groundbreaking military research by
              funding in the projects. Due to our specialty in these industries,
              we meticulously construct a diverse and efficient portfolio that
              is expected to deliver results and create value.
            </p>
          </section>
        </Container>
      </div>
      <GetInTouch />
    </div>
  );
}
