import React, { useEffect } from "react";
import "../css/about.css";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import GetInTouch from "./GetInTouch";
import founder from "../images/MD SIR PHOTO-02 (1).png"
export default function Aboutus() {
  useEffect(() => {
    // Scroll to the top of the window when the component loads
    window.scroll(0, 0);
  }, []); // Empty dependency array ensures this runs only on mount

  return (
    <div>
      <div className="Aboutus-page">
        <Container fluid>
          <div className="top-links">
            <Link to="/">
              {" "}
              <span className="home-link">Home</span>
            </Link>
            <FontAwesomeIcon icon={faArrowRight} className="ps-3 pe-3" />
            <span className="page-link">About us</span>
          </div>
          <div className="About-page-content">
            <Row className="achievements-row">
              <div className="reviews__arrow">
                <span></span>
                <span></span>
                <span></span>
              </div>
              <Col lg={6}>
                <img src={founder} alt="Navatris Founder "/>
              </Col>
              <Col lg={6} className="ps-5">
                <h6>Meet the Driving Force behind Navatris</h6>
                <h2>About Us</h2>
                <p>
                  Navatris Investments is a boutique family investment firm of
                  Dr D Srinivasa Reddy, who is also the founder and chairperson
                  of a leading pharmaceutical group - Optimus.{" "}
                </p>
                <p>
                  Packed with unmatched experience and extensive knowledge of
                  running a successful pharma group has stepped into the
                  financial world with a commitment to support startups and
                  businesses.
                </p>
                <p>
                  Dr D Srinivasa Reddy's core belief makes the investment
                  opportunity available to all, not just the privileged.
                </p>
              </Col>
            </Row>
          </div>
          <div className="wide-range-services">
            <Row className="d-flex justify-content-center align-items-center">
              <Col lg={6}>
                <h2>Our Mission</h2>
              </Col>
              <Col>
                <p>
                  To build an effective long-term investment portfolio focusing
                  on cash flow-generating investments that ultimately allow us
                  to inspire others, contribute to charitable giving, and
                  participate in economic growth.{" "}
                </p>
              </Col>
            </Row>
          </div>
          <section className="vision">
            <h2>Our Vision</h2>
           <p className="text-[14px] leading-7 text-[#5d6063]"> We aim to be most successful and top performing family office by
            forging a lasting legacy of financial excellence. We intent to
            empower our family to realize their aspirations and achieve their
            most ambitious dreams. By leveraging our family values with
            long-term perspectives, we empower future generations to achieve
            their dreams with our financial solutions for greater returns. We
            promote and nurture collaborative and supportive ecosystem by
            leveraging our wisdom and expertise to deliver exceptional outcomes.</p>{" "}
          </section>
        </Container>
      </div>
      <GetInTouch />
    </div>
  );
}
