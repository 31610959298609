import React,{useEffect} from 'react'
import BannerHero from './BannerHero'

export default function HomePage() {
  useEffect(() => {
    // Scroll to the top of the window when the component loads
    window.scroll(0, 0);
  }, []); // Empty dependency array ensures this runs only on mount

  return (
    <>
    <BannerHero />
    </>
  )
}
