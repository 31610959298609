import React from 'react'
import '../css/Banner.css'
import { Container, Row, Col } from 'react-bootstrap'
import Logo from '../images/Navatris logo-01.png'
import { Link } from 'react-router-dom'
import WhatsAppButton from './WhatsAppButton'
export default function Footer() {
    return (
        <div>
            {/* <WhatsAppButton /> */}
            <footer className='footer-nav'>
                <Container fluid>
                    <Row>
                        <Col lg={2} md={2} className='logo-image'>
                            <Row>
                                <img src={Logo} alt="NavatrisLogo" />
                            </Row>
                            <Row className='mt-lg-5'>
                            <Link>Privacy policy</Link>
                            </Row>
                        </Col>
                        
                        <Col lg={1}></Col>
                        <Col lg={3} >
                            <Row className='justify-content-center alignments-center links'>
                                <ul>
                                    <li>Location</li>
                                    <li><Link to='https://maps.app.goo.gl/7kMHr2eecQ7VBdhMA' target="_blank">Endula Empresa, 4th Floor, Kondapur, Hyderabad , Telangana-500084.</Link></li>
                                
                                    <li><Link to='mailto:info@kodansoft.com'>info@dsroffice.com</Link></li>
                                </ul>
                            </Row>
                        </Col>
                        <Col lg={1}></Col>
                        <Col lg={2} md={6}>
                            <Row className='justify-content-center alignments-center links'>
                                <ul>
                                    <li>Follow us</li>
                                    <li><Link>Instagram</Link></li>
                                    <li><Link to='https://www.linkedin.com/company/navatris-investments?trk=public_profile_topcard-current-company' target="_blank">Linkedin</Link></li>
                                    <li><Link to=''>Twitter</Link></li>
                                </ul>
                            </Row>
                        </Col>
                        <Col lg={3} md={6}>
                            <Row className='justify-content-center alignments-center links'>
                                <ul>
                                    <li>Pages</li>
                                    <li><Link to='/Aboutus'>About us</Link></li>
                                    <li><Link to='/Industries'>Industries</Link></li>
                                    <li><Link to='/What-we-do'>what we do</Link></li>
                                </ul>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </div>
    )
}
