import React  from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../css/Banner.css";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import RealEstateIcon from "../images/Real Estate 01.png";
import FinanceIcon from "../images/Finance-01.png";
import ntIcon1 from "../images/NT HOME PAGE ICONS-01.png";
import ntIcon2 from "../images/NT HOME PAGE ICONS-02.png";
import ntIcon3 from "../images/NT HOME PAGE ICONS-03.png";
import InvestmentIcon1 from "../images/INVESTMENT ICONS-01.png";
import InvestmentIcon2 from "../images/INVESTMENT ICONS-02.png";
import InvestmentIcon3 from "../images/INVESTMENT ICONS-03.png";
import InvestmentIcon4 from "../images/INVESTMENT ICONS-04.png";
import InvestmentIcon5 from "../images/INVESTMENT ICONS-05.png";
import InvestmentIcon6 from "../images/INVESTMENT ICONS-06.png";
import InvestmentIcon7 from "../images/INVESTMENT ICONS-07.png";
import InvestmentIcon8 from "../images/INVESTMENT ICONS-08.png";
import InvestmentIcon9 from "../images/INVESTMENT ICONS-09.png";
import InvestmentIcon10 from "../images/INVESTMENT ICONS-10.png";
import InvestmentIcon11 from "../images/INVESTMENT ICONS-11.png";
import InvestmentIcon12 from "../images/INVESTMENT ICONS-12.png";
import InvestmentIcon13 from "../images/INVESTMENT ICONS-13.png";
import InvestmentIcon14 from "../images/INVESTMENT ICONS-14.png";
import achievement from "../images/image-02.png"
import debdIonc from "../images/debdIcon.png";
import defenceIcon from "../images/defenceIcon.png";
import lifeScience from "../images/lifeScience-icon.png";
import ventureIcon from "../images/ventureIcon.png";
import investIcon from "../images/investmentIcons.png";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import GetInTouch from "./GetInTouch";
export default function BannerHero() {
  const settings = {
    infinite: true, // Enables the looping effect
    speed: 500, // The transition speed between slides
    slidesToShow: 3, // Shows 3 slides at once (you can adjust this as needed)
    slidesToScroll: 1, // Scrolls 1 slide at a time
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1, // Show 1 slide on small screens
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 2, // Show 3 slides on medium screens
          slidesToScroll: 1,
        },
      },
    ],
  };
  

  return (
    <>
      <section className="main">
        <Container fluid>
          <div>
            <div className="work-together">
              <div className="fw700 h3 fz21_1400">Find out who we are</div>
              <p className="fw400 h5 color-gray">
                Meet the engine of our firm and learn about us.
              </p>
            </div>
            <div className="main-title">
              <div className="main-title__lineTop">
                <span></span>
                <span></span>
                <span></span>
              </div>

              <p className="h1 fw700 color-white main-title__top bg-blue">
                Future of Investment{" "}
              </p>
              <span className="main-title__lineCenter"></span>
              <p className="h1 fw700 color-white main-title__bottom bg-blue2">
                Money earning money!
              </p>
              <div className="main-title__lineBottom">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
            <div className="were">
              <div className="fw700 h2 fz21_1400">We’re</div>
              <p className="fw400 h5 color-gray fz14_1400 mt-24">
                Navatris Investments is a boutique family investment firm.
              </p>
            </div>
          </div>
        </Container>
      </section>
      <section className="about-company">
        <Container fluid>
          <Row>
            <Col xl={6} md={12}>
              <Row className="justify-contnet-center align-items-center">
                <Col lg={7}>
                  <div className="about-company__item">
                    <img src={ntIcon1} alt="about Icons"/>
                  </div>
                </Col>
                <Col lg={1}></Col>
                <Col lg={4}>
                  <Row>
                    <div className="about-company__item">
                      <img src={ntIcon2} alt="about Icons"/>
                    </div>
                  </Row>
                  <Row className="mt-5">
                    <div className="about-company__item">
                      <img src={ntIcon3} alt="about Icons"/>
                    </div>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col xl={6} md={12} className="about-content-column">
              <div className="about-content">
                <h6>we are</h6>
                <h2>About company</h2>
                <p>
                  Navatris Investments is a boutique family investment firm of 
                  Dr D Srinivasa Reddy, who is also the founder and chairperson
                  of a leading pharmaceutical group - Optimus.
                  <br />
                  <br />
                  Packed with unmatched experience and extensive knowledge of
                  running a successful pharma group has stepped into the
                  financial world with a commitment to support startups and
                  businesses.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="Goal">
        <Container fluid>
          <div className="listIcons bg-blue d-lg-flex d-sm-block align-items-center">
            <div className="listIcons-title align-self-center">
              <h6>Our Goal</h6>
              <h2>Wealth Growth</h2>
            </div>
            <p>
              To protect and progressively enhance wealth with time. Committed
              to implementing the saying “money earning money” .{" "}
            </p>
          </div>
        </Container>
      </section>
      <section className="Industries">
        <Container fluid>
          <div className="who-reviews">
            <h6>we work in the industries</h6>
            <h2>Domains</h2>
          </div>
          <Slider {...settings}>
            <div>
              <div className="Domain-wrap relative">
                <div className="img__wrap">
                  <img src={RealEstateIcon} alt="RealEstateIcon"/>
                </div>
                <h3>Real Estate</h3>
                <p>
                  We essentially target quality real estate development projects
                  particularly housing and office complex that has high
                  profitability and potential for value-based growth.
                </p>
                <Link
                  className="absolute bottom-12"
                  to="/Industries#realEstate"
                >
                  view briefly <FontAwesomeIcon icon={faArrowRight} />
                </Link>
              </div>
            </div>
            <div>
              <div className="Domain-wrap">
                <div className="img__wrap">
                  <img src={investIcon} alt="investIcon"/>
                </div>
                <h3>Investment Equity</h3>
                <p>
                  We provide investments in both listed and unlisted market
                  spaces in order to maximise the growth potential and
                  capitalize the emerging enterprise.
                </p>
                <Link
                  className="absolute bottom-12"
                  to="/Industries#investMent"
                >
                  view briefly <FontAwesomeIcon icon={faArrowRight} />
                </Link>
              </div>
            </div>
            <div>
              <div className="Domain-wrap">
                <div className="img__wrap">
                  <img src={debdIonc} alt="Debt Financing Icon"/>
                </div>
                <h3>Debt Financing</h3>
                <p>
                  We support businesses and entrepreneurs by offering tailored
                  financial solutions through debt financing, enabling them to
                  achieve goals and scale to new heights.
                </p>
                <Link
                  className="absolute bottom-10 sm:bottom-12"
                  to="/Industries#debtFinance"
                >
                  view briefly <FontAwesomeIcon icon={faArrowRight} />
                </Link>
              </div>
            </div>
            <div>
              <div className="Domain-wrap  ">
                <div className="img__wrap">
                  <img src={ventureIcon} alt="Venture Capitalism Icon"/>
                </div>
                <h3>Venture Capitalism</h3>
                <p>
                  We join with startups and visionary entrepreneurs who aim in
                  driving revolutionary change. We empower them to turn
                  innovative ideas into actionable reality.
                </p>
                <Link className="absolute bottom-12" to="/Industries#venture">
                  view briefly <FontAwesomeIcon icon={faArrowRight} />
                </Link>
              </div>
            </div>
            <div>
              <div className="Domain-wrap">
                <div className="img__wrap">
                  <img src={lifeScience} alt="Life Sciences Icon"/>
                </div>
                <h3>Life Sciences</h3>
                <p>
                  Leveraging our founder’s expertise in pharma field,
                  investments made by us are aimed at promoting a higher quality
                  of health care in the world.
                </p>
                <Link
                  className="absolute bottom-12"
                  to="/Industries#LifeScience"
                >
                  view briefly <FontAwesomeIcon icon={faArrowRight} />
                </Link>
              </div>
            </div>
            <div>
              <div className="Domain-wrap" >
                <div className="img__wrap">
                  <img src={defenceIcon} alt="Defence Icon"/>
                </div>
                <h3>Defence</h3>
                <p>
                  At Navatris, we dedicate our focus to support the innovative
                  business ideas that build visionary equipment in the defence
                  sector.{" "}
                </p>
                <Link className="absolute bottom-12" to="/Industries#defence">
                  view briefly <FontAwesomeIcon icon={faArrowRight} />
                </Link>
              </div>
            </div>
            <div>
              <div className="Domain-wrap">
                <div className="img__wrap">
                  <img src={FinanceIcon} alt="Finance Icon"/>
                </div>
                <h3>Finance</h3>
                <p>
                  Our investments within finance focus on innovative
                  technologies and the firms that transform the financing
                  industry.
                </p>
                <Link className="absolute bottom-12" to="/Industries#Finance">
                  View briefly <FontAwesomeIcon icon={faArrowRight} />
                </Link>
              </div>
            </div>
          </Slider>
        </Container>
      </section>
      <section className="what-we-do">
        <Container fluid>
          <div className="what-we-do-content">
            <Row className="achievements-row">
              <Col lg={7} className="invest-icon">
                <Container>
                  <Row>
                    <Col>
                      {" "}
                      <img src={InvestmentIcon1} alt="InvestmentIcon1" />
                    </Col>
                    <Col>
                      {" "}
                      <img src={InvestmentIcon2} alt="InvestmentIcon2" />
                    </Col>
                    <Col>
                      {" "}
                      <img src={InvestmentIcon3} alt="InvestmentIcon3" />
                    </Col>
                    <Col>
                      {" "}
                      <img src={InvestmentIcon4} alt="InvestmentIcon4" />
                    </Col>
                    <Col>
                      {" "}
                      <img src={InvestmentIcon5} alt="InvestmentIcon5" />
                    </Col>
                  </Row>
                  <Row className="mt-xl-5">
                    <Col>
                      {" "}
                      <img src={InvestmentIcon6} alt="InvestmentIcon6" />
                    </Col>
                    <Col>
                      {" "}
                      <img src={InvestmentIcon7} alt="InvestmentIcon7" />
                    </Col>
                    <Col>
                      {" "}
                      <img src={InvestmentIcon8} alt="InvestmentIcon8" />
                    </Col>
                    <Col>
                      {" "}
                      <img src={InvestmentIcon9} alt="InvestmentIcon9" />
                    </Col>
                  </Row>
                  <Row className="mt-xl-5">
                    <Col>
                      {" "}
                      <img src={InvestmentIcon10} alt="InvestmentIcon10" />
                    </Col>
                    <Col>
                      {" "}
                      <img src={InvestmentIcon11} alt="InvestmentIcon11" />
                    </Col>
                    <Col>
                      {" "}
                      <img src={InvestmentIcon12} alt="InvestmentIcon12" />
                    </Col>
                    <Col>
                      {" "}
                      <img src={InvestmentIcon13} alt="InvestmentIcon13" />
                    </Col>
                    <Col>
                      {" "}
                      <img src={InvestmentIcon14} alt="InvestmentIcon14" />
                    </Col>
                  </Row>
                </Container>
              </Col>
              <Col lg={5} className="mb-md-5">
                <div className="d-flex justify-content-between align-items-center right-content">
                  <h6>How we Invest</h6>
                  <Link to="/What-we-do">
                    <h7>
                      View more <FontAwesomeIcon icon={faArrowRight} />
                    </h7>
                  </Link>
                </div>
                <div className="left-title">
                  {" "}
                  <h2>Our Investment Criteria</h2>
                </div>
                <div className="left-content">
                  <p>
                    We focus on balancing the risk and making more profit, we
                    make investments with ourplanned strategies which are
                    completely data driven and make informed decisions
                    considering from the minds of experts.{" "}
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <div className="contentList__line">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </section>
      {/* <section className="achievements">
        <Container fluid>
          <Row className="achievements-row">
            <Col lg={6} className="achievements-wrap">
              <div>
                <img src={achievement} alt="achievement Icon"/>
              </div>
            </Col>
            <Col lg={2}></Col>
            <Col lg={4} className="right-achivements flex justify-center items-center">
              <div>
              <h6>With our super powers we have reached</h6>
              <h2>Our 2 years of achievements</h2>
              </div>
            </Col>
          </Row>
        </Container>
      </section> */}
      <GetInTouch />
    </>
  );
}
