import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import CustomNavbar from "./components/CustomNavbar";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./components/HomePage";
import Footer from "./components/Footer";
import Industries from "./components/Industries";
import Aboutus from "./components/Aboutus";
import WhatWeDo from "./components/WhatWeDo";
import "./css/Responsive.css";
import Funds from "./components/Funds";
function App() {
  return (
    <>
      <BrowserRouter>
        <CustomNavbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/Aboutus" element={<Aboutus />} />
          <Route path="/What-we-do" element={<WhatWeDo />} />
          <Route path="/Industries" element={<Industries />} />
          <Route path="/Funds" element={<Funds />} />
          <Route path="/Industries" element={<Industries />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
